export const DCPStatuses = {
  dispatched: 'dispatched',
  executing: 'executing',
  queued: 'queued',
  failed: 'failed',
  error: 'error',
  succeeded: 'succeeded',
  cancelled: 'cancelled',
  scheduled: 'scheduled',
  downloading: 'downloading',
  downloaded: 'downloaded',
  expired: 'expired',
  pending: 'pending',
  delivered: 'delivered',
  pending_cancellation: 'pending_cancellation',
  unarchiving: 'unarchiving',
  downloading_unarchiving: 'downloading_unarchiving',
  pending_fulfilment_unarchiving: 'pending_fulfilment_unarchiving',
  archived: 'archived',
  downloading_archived: 'downloading_archived',
  pending_fulfilment_archived: 'pending_fulfilment_archived',
  stalled: 'stalled',
  downloading_stalled: 'downloading_stalled',
  pending_fulfilment_stalled: 'pending_fulfilment_stalled'
}

export const ShipmentStatuses = {
  Pending: 'Pending',
  InfoReceived: 'InfoReceived',
  InTransit: 'InTransit',
  OutForDelivery: 'OutForDelivery',
  AttemptFail: 'AttemptFail',
  Delivered: 'Delivered',
  Exception: 'Exception',
  Expired: 'Expired'
}

export const DCPDeliveryType = {
  THEATRE_APPLIANCE: 'THEATRE_APPLIANCE',
  PARTNERS: 'partners',
  ELECTRONIC: 'ELECTRONIC'
}

export const KDMStatuses = {
  success: 'success',
  emailed: 'emailed',
  inprogress: 'inprogress',
  action_required: 'action_required',
  failed: 'failed',
  generated: 'generated',
  unavailable: 'unavailable',
  inProgress: 'inProgress'
}

export const CPLAttributes = {
  type: 1,
  resolution: 2,
  stereoType: 3,
  aspectRatio: 4,
  audioFormats: 5,
  language: 6,
  subtitleLanguage: 7,
  closedCaptions: 8,
  ratings: 9
}

export const WebSocketSubscriptions = {
  SYSTEM_STATE: 'subscribe_systemstate_events',
  APPLIANCE_STATUS: 'subscribe_appliancestatus_changed',
  DOWNLOADS: 'subscribe_download_events'
}

export const WebSocketMessageTypes = {
  SYSTEM_STATE: 'systemstate_changed',
  APPLIANCE_STATUS: 'appliancestatus_changed',
  DOWNLOADS: 'downloads_progressed'
}

export const Enviroinment = {
  test: 'test'
}

export const FilterTypes = {
  THEATRES: 'Theatres',
  KDM_VALIDITY: 'KDM Validity',
  KDM_VALIDITY_OPTIONS: {
    VALID_NOW: {
      displayName: 'Valid Now',
      value: 'validNow'
    },
    FUTURE: {
      displayName: 'Valid in Future',
      value: 'future'
    },
    EXPIRING: {
      displayName: 'Expiring',
      value: 'expiring'
    },
    EXPIRED: {
      displayName: 'Expired',
      value: 'expired'
    }
  },
  COMPOSTION_TYPE: 'Composition type',
  COMPOSTION_TYPE_OPTIONS: {
    FEATURE_FEATURES: {
      displayName: 'Feature/Features',
      value: 'feature'
    },
    ADVERTISEMENT: {
      displayName: 'Advertisement',
      value: 'advertisement'
    },
    TRAILER_TEASER_PROMO: {
      displayName: 'Trailer/Teaser/Promo',
      value: 'teaser_trailer_promo'
    },
    PSA_POLICY: {
      displayName: 'PSA/Policy',
      value: 'psa_policy'
    },
    OTHERS: {
      displayName: 'Others',
      value: 'others'
    }
  },
  DCP_DOWNLOAD_STATUS: 'DCP Download status',
  DCP_DOWNLOAD_STATUS_OPTIONS: {
    PENDING: {
      displayName: 'Pending',
      value: 'pending'
    },
    SCHEDULED: {
      displayName: 'Scheduled',
      value: 'scheduled'
    },
    UNARCHIVING: {
      displayName: 'Unarchiving',
      value: 'unarchiving'
    },
    DOWNLOADING: {
      displayName: 'Downloading',
      value: 'downloading'
    },
    DOWNLOADED: {
      displayName: 'Downloaded',
      value: 'downloaded'
    },
    SHIPPED: {
      displayName: 'Shipped',
      value: 'shipped'
    },
    DELIVERED: {
      displayName: 'Delivered',
      value: 'delivered'
    },
    CANCELLED: {
      displayName: 'Cancelled',
      value: 'cancelled'
    },
    FAILED: {
      displayName: 'Failed',
      value: 'failed'
    }
  },
  DCP_INGEST_STATUS: 'DCP ingest status',
  DCP_INGEST_STATUS_OPTIONS: {
    PENDING_INGEST: {
      displayName: 'Pending Ingest',
      value: 'initiation_pending,initiation_expired,waiting'
    },
    INGEST_IN_PROGRESS: {
      displayName: 'Ingest In Progress',
      value: 'initiated,ingesting'
    },
    INGESTED: {
      displayName: 'Ingested',
      value: 'ingested'
    },
    INGEST_FAILED: {
      displayName: 'Ingest Failed',
      value: 'failed,expired'
    },
    CANCELLED: {
      displayName: 'Cancelled',
      value: 'cancelled'
    }
  },
  KDM_DELIVERY_STATUS: 'KDM delivery status',
  KDM_DELIVERY_STATUS_OPTIONS: {
    ACTION_REQUIRED: {
      displayName: 'Action Required',
      value: 'action_required'
    },
    // IN_PROGRESS: {
    //   displayName: 'In Progress',
    //   value: 'in_progress'
    // },
    AVAILABLE: {
      displayName: 'Available',
      value: 'available'
    },
    EMAILED: {
      displayName: 'Emailed',
      value: 'emailed'
    }
  },
  KDM_INGEST_STATUS: 'KDM ingest status',
  KDM_INGEST_STATUS_OPTIONS: {
    PENDING_INGEST: {
      displayName: 'Pending Ingest',
      value: 'initiation_pending,initiation_expired,waiting'
    },
    INGEST_IN_PROGRESS: {
      displayName: 'Ingest In Progress',
      value: 'initiated,ingesting'
    },
    INGESTED: {
      displayName: 'Ingested',
      value: 'ingested'
    },
    INGEST_FAILED: {
      displayName: 'Ingest Failed',
      value: 'failed,expired'
    },
    CANCELLED: {
      displayName: 'Cancelled',
      value: 'cancelled'
    }
  }
}

export const customDateRangeShortcuts = [
  {
    dateRange: [
      new Date(new Date(new Date().getTime() - 14 * 86400000).setHours(0, 0, 0, 0)), // Past 2 weeks
      new Date(new Date().setHours(23, 59, 59, 999))
    ],
    includeTime: false,
    label: 'Past 2 weeks'
  },
  {
    dateRange: [
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate()
        ).setHours(0, 0, 0, 0)
      ), // Past month
      new Date(new Date().setHours(23, 59, 59, 999))
    ],
    includeTime: false,
    label: 'Past month'
  },
  {
    dateRange: [
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 3,
          new Date().getDate()
        ).setHours(0, 0, 0, 0)
      ), // Past 3 months
      new Date(new Date().setHours(23, 59, 59, 999))
    ],
    includeTime: false,
    label: 'Past 3 months'
  },
  {
    dateRange: [
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 6,
          new Date().getDate()
        ).setHours(0, 0, 0, 0)
      ), // Past 6 months
      new Date(new Date().setHours(23, 59, 59, 999))
    ],
    includeTime: false,
    label: 'Past 6 months'
  },
  {
    dateRange: [
      new Date(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 12,
          new Date().getDate()
        ).setHours(0, 0, 0, 0)
      ), // Past 12 months
      new Date(new Date().setHours(23, 59, 59, 999))
    ],
    includeTime: false,
    label: 'Past 12 months'
  },
  {
    dateRange: [
      new Date(
        new Date(
          new Date().getFullYear() - 2,
          new Date().getMonth(),
          new Date().getDate()
        ).setHours(0, 0, 0, 0)
      ), // Past 2 years
      new Date(new Date().setHours(23, 59, 59, 999))
    ],
    includeTime: false,
    label: 'Past 2 years'
  },
  {
    dateRange: [null, null],
    includeTime: false,
    label: 'Maximum'
  }
]

export const kdmValidityOptions = [
  {
    id: FilterTypes.KDM_VALIDITY_OPTIONS.VALID_NOW.value,
    name: FilterTypes.KDM_VALIDITY_OPTIONS.VALID_NOW.displayName,
    displayName: FilterTypes.KDM_VALIDITY_OPTIONS.VALID_NOW.displayName,
    type: FilterTypes.KDM_VALIDITY,
    value: FilterTypes.KDM_VALIDITY_OPTIONS.VALID_NOW.value
  },
  {
    id: FilterTypes.KDM_VALIDITY_OPTIONS.FUTURE.value,
    name: FilterTypes.KDM_VALIDITY_OPTIONS.FUTURE.displayName,
    displayName: FilterTypes.KDM_VALIDITY_OPTIONS.FUTURE.displayName,
    type: FilterTypes.KDM_VALIDITY,
    value: FilterTypes.KDM_VALIDITY_OPTIONS.FUTURE.value
  },
  {
    id: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRING.value,
    name: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRING.displayName,
    displayName: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRING.displayName,
    type: FilterTypes.KDM_VALIDITY,
    value: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRING.value
  },
  {
    id: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRED.value,
    name: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRED.displayName,
    displayName: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRED.displayName,
    type: FilterTypes.KDM_VALIDITY,
    value: FilterTypes.KDM_VALIDITY_OPTIONS.EXPIRED.value
  }
]
export const compositionTypeOptions = [
  {
    id: FilterTypes.COMPOSTION_TYPE_OPTIONS.FEATURE_FEATURES.value,
    name: FilterTypes.COMPOSTION_TYPE_OPTIONS.FEATURE_FEATURES.displayName,
    displayName: FilterTypes.COMPOSTION_TYPE_OPTIONS.FEATURE_FEATURES.displayName,
    type: FilterTypes.COMPOSTION_TYPE,
    value: FilterTypes.COMPOSTION_TYPE_OPTIONS.FEATURE_FEATURES.value
  },
  {
    id: FilterTypes.COMPOSTION_TYPE_OPTIONS.ADVERTISEMENT.value,
    name: FilterTypes.COMPOSTION_TYPE_OPTIONS.ADVERTISEMENT.displayName,
    displayName: FilterTypes.COMPOSTION_TYPE_OPTIONS.ADVERTISEMENT.displayName,
    type: FilterTypes.COMPOSTION_TYPE,
    value: FilterTypes.COMPOSTION_TYPE_OPTIONS.ADVERTISEMENT.value
  },
  {
    id: FilterTypes.COMPOSTION_TYPE_OPTIONS.TRAILER_TEASER_PROMO.value,
    name: FilterTypes.COMPOSTION_TYPE_OPTIONS.TRAILER_TEASER_PROMO.displayName,
    displayName: FilterTypes.COMPOSTION_TYPE_OPTIONS.TRAILER_TEASER_PROMO.displayName,
    type: FilterTypes.COMPOSTION_TYPE,
    value: FilterTypes.COMPOSTION_TYPE_OPTIONS.TRAILER_TEASER_PROMO.value
  },
  {
    id: FilterTypes.COMPOSTION_TYPE_OPTIONS.PSA_POLICY.value,
    name: FilterTypes.COMPOSTION_TYPE_OPTIONS.PSA_POLICY.displayName,
    displayName: FilterTypes.COMPOSTION_TYPE_OPTIONS.PSA_POLICY.displayName,
    type: FilterTypes.COMPOSTION_TYPE,
    value: FilterTypes.COMPOSTION_TYPE_OPTIONS.PSA_POLICY.value
  },
  {
    id: FilterTypes.COMPOSTION_TYPE_OPTIONS.OTHERS.value,
    name: FilterTypes.COMPOSTION_TYPE_OPTIONS.OTHERS.displayName,
    displayName: FilterTypes.COMPOSTION_TYPE_OPTIONS.OTHERS.displayName,
    type: FilterTypes.COMPOSTION_TYPE,
    value: FilterTypes.COMPOSTION_TYPE_OPTIONS.OTHERS.value
  }
]
export const dcpDownloadStatusOptions = [
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.PENDING.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.PENDING.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.PENDING.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.PENDING.value
  },
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.SCHEDULED.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.SCHEDULED.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.SCHEDULED.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.SCHEDULED.value
  },
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.UNARCHIVING.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.UNARCHIVING.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.UNARCHIVING.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.UNARCHIVING.value
  },
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DOWNLOADING.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DOWNLOADING.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DOWNLOADING.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DOWNLOADING.value
  },
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DOWNLOADED.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DOWNLOADED.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DOWNLOADED.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DOWNLOADED.value
  },
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.SHIPPED.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.SHIPPED.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.SHIPPED.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.SHIPPED.value
  },
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DELIVERED.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DELIVERED.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DELIVERED.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.DELIVERED.value
  },
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.CANCELLED.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.CANCELLED.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.CANCELLED.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.CANCELLED.value
  },
  {
    id: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.FAILED.value,
    name: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.FAILED.displayName,
    displayName: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.FAILED.displayName,
    type: FilterTypes.DCP_DOWNLOAD_STATUS,
    value: FilterTypes.DCP_DOWNLOAD_STATUS_OPTIONS.FAILED.value
  }
]
export const dcpIngestStatusOptions = [
  {
    id: FilterTypes.DCP_INGEST_STATUS_OPTIONS.PENDING_INGEST.value,
    name: FilterTypes.DCP_INGEST_STATUS_OPTIONS.PENDING_INGEST.displayName,
    displayName: FilterTypes.DCP_INGEST_STATUS_OPTIONS.PENDING_INGEST.displayName,
    type: FilterTypes.DCP_INGEST_STATUS,
    value: FilterTypes.DCP_INGEST_STATUS_OPTIONS.PENDING_INGEST.value
  },
  {
    id: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGEST_IN_PROGRESS.value,
    name: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGEST_IN_PROGRESS.displayName,
    displayName: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGEST_IN_PROGRESS.displayName,
    type: FilterTypes.DCP_INGEST_STATUS,
    value: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGEST_IN_PROGRESS.value
  },
  {
    id: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGESTED.value,
    name: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGESTED.displayName,
    displayName: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGESTED.displayName,
    type: FilterTypes.DCP_INGEST_STATUS,
    value: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGESTED.value
  },
  {
    id: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGEST_FAILED.value,
    name: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGEST_FAILED.displayName,
    displayName: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGEST_FAILED.displayName,
    type: FilterTypes.DCP_INGEST_STATUS,
    value: FilterTypes.DCP_INGEST_STATUS_OPTIONS.INGEST_FAILED.value
  },
  {
    id: FilterTypes.DCP_INGEST_STATUS_OPTIONS.CANCELLED.value,
    name: FilterTypes.DCP_INGEST_STATUS_OPTIONS.CANCELLED.displayName,
    displayName: FilterTypes.DCP_INGEST_STATUS_OPTIONS.CANCELLED.displayName,
    type: FilterTypes.DCP_INGEST_STATUS,
    value: FilterTypes.DCP_INGEST_STATUS_OPTIONS.CANCELLED.value
  }
]
export const kdmDeliveryStatusOptions = [
  {
    id: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.ACTION_REQUIRED.value,
    name: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.ACTION_REQUIRED.displayName,
    displayName: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.ACTION_REQUIRED.displayName,
    type: FilterTypes.KDM_DELIVERY_STATUS,
    value: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.ACTION_REQUIRED.value
  },
  // {
  //   id: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.IN_PROGRESS.value,
  //   name: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.IN_PROGRESS.displayName,
  //   displayName: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.IN_PROGRESS.displayName,
  //   type: FilterTypes.KDM_DELIVERY_STATUS,
  //   value: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.IN_PROGRESS.value
  // },
  {
    id: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.AVAILABLE.value,
    name: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.AVAILABLE.displayName,
    displayName: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.AVAILABLE.displayName,
    type: FilterTypes.KDM_DELIVERY_STATUS,
    value: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.AVAILABLE.value
  },
  {
    id: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.EMAILED.value,
    name: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.EMAILED.displayName,
    displayName: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.EMAILED.displayName,
    type: FilterTypes.KDM_DELIVERY_STATUS,
    value: FilterTypes.KDM_DELIVERY_STATUS_OPTIONS.EMAILED.value
  }
]
export const kdmIngestStatusOptions = [
  {
    id: FilterTypes.KDM_INGEST_STATUS_OPTIONS.PENDING_INGEST.value,
    name: FilterTypes.KDM_INGEST_STATUS_OPTIONS.PENDING_INGEST.displayName,
    displayName: FilterTypes.KDM_INGEST_STATUS_OPTIONS.PENDING_INGEST.displayName,
    type: FilterTypes.KDM_INGEST_STATUS,
    value: FilterTypes.KDM_INGEST_STATUS_OPTIONS.PENDING_INGEST.value
  },
  {
    id: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGEST_IN_PROGRESS.value,
    name: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGEST_IN_PROGRESS.displayName,
    displayName: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGEST_IN_PROGRESS.displayName,
    type: FilterTypes.KDM_INGEST_STATUS,
    value: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGEST_IN_PROGRESS.value
  },
  {
    id: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGESTED.value,
    name: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGESTED.displayName,
    displayName: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGESTED.displayName,
    type: FilterTypes.KDM_INGEST_STATUS,
    value: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGESTED.value
  },

  {
    id: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGEST_FAILED.value,
    name: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGEST_FAILED.displayName,
    displayName: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGEST_FAILED.displayName,
    type: FilterTypes.KDM_INGEST_STATUS,
    value: FilterTypes.KDM_INGEST_STATUS_OPTIONS.INGEST_FAILED.value
  },
  {
    id: FilterTypes.KDM_INGEST_STATUS_OPTIONS.CANCELLED.value,
    name: FilterTypes.KDM_INGEST_STATUS_OPTIONS.CANCELLED.displayName,
    displayName: FilterTypes.KDM_INGEST_STATUS_OPTIONS.CANCELLED.displayName,
    type: FilterTypes.KDM_INGEST_STATUS,
    value: FilterTypes.KDM_INGEST_STATUS_OPTIONS.CANCELLED.value
  }
]
export const UserRoles = {
  QWC_ADMIN: 'QWC Admin',
  QWC_DEVICE_MANAGER : 'QWC Device Manager'
}
