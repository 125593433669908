import _ from 'lodash'
import moment from 'moment'
import 'moment-duration-format'
import Qs from 'qs'
import { ZIPPO_URL } from '../config'
import {enums as libEnums} from 'qw-ui-lib-v2'

export function join(array) {
  if (_.compact(array).length) {
    return _.compact(array).reduce((s, i) => {
      return `${s}, ${i}`
    })
  }
  return ''
}

export function getDuration(duration) {
  let formatted = moment.duration(parseInt(duration, 10), 'seconds').format('hh:mm:ss')

  if (formatted.length === 5) {
    formatted = `00:${formatted}`
  }
  if (formatted.length === 2) {
    formatted = `00:00:${formatted}`
  }
  return formatted
}

export function formatDateTime(momentObj) {
  return momentObj.format('DD MMM YYYY hh:mm A')
}

export function getDataFromUrl() {
  return Qs.parse(window.location.search.replace('?', ''))
}

export function getAccessToken() {
  const { accessToken } = getDataFromUrl()
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
  }
  return localStorage.getItem('accessToken')
}

export function getComapnyId() {
  return getDataFromUrl().companyID
}

export function frameTheatreAddress(theatre) {
  const address = []

  if (!_.isEmpty(theatre.address)) {
    const {
      address: { city, province, country, state }
    } = theatre
    if (!_.isEmpty(city)) {
      address.push(city)
    }
    if (!_.isEmpty(province)) {
      address.push(province)
    }
    if (!_.isEmpty(state)) {
      address.push(state)
    }
    if (!_.isEmpty(country)) {
      address.push(country)
    }
  } else {
    if (!_.isEmpty(theatre.city)) {
      address.push(theatre.city.name)
    }

    if (!_.isEmpty(theatre.province)) {
      address.push(theatre.province.name)
    }

    if (!_.isEmpty(theatre.country)) {
      address.push(theatre.country.name)
    }
  }
  return address.join(', ')
}

export function downloadKdm(fileName, data) {
  const form = document.createElement('form')
  const node = document.createElement('input')
  node.type = 'hidden'
  form.action = `${ZIPPO_URL}/?filename=${fileName}.zip`
  form.method = 'POST'
  data.forEach(item => {
    node.name = item.filename
    node.value = item.url
    form.appendChild(node.cloneNode())
  })
  form.style.display = 'none'
  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}
export function formatMinutestoHours(timeInMinutes) {
  const hours = Math.floor(timeInMinutes / 60)
  const minutes = timeInMinutes % 60
  return { hours, minutes }
}

export function frameLocation(data)
{
  const {type,displayName,details} = data
  let formattedLocation = []
  formattedLocation.push(displayName)
  if(type === libEnums.FilterTypes["city"]){
      if(details.province.name)
      formattedLocation.push(details.province.name)
  }
  if(type === libEnums.FilterTypes["province"] || type === libEnums.FilterTypes["city"])
  {
    if(details.country.name)
    formattedLocation.push(details.country.name)
  }
  return formattedLocation.join(", ")
}
export function  capitalizeFirstletter(word)

{
  let formattedWord = word.charAt(0).toUpperCase() + word.slice(1)
  return  formattedWord
}

export const formatRegionLabel = (region) => {
  if (!region) return '';
  const location = [];
  if (region.city) location.push(region.city.name);
  if (region.province) location.push(region.province.name);
  else if (region.state) location.push(region.state.name);
  if (region.country) location.push(region.country.name);
  return location.join(', ');
};