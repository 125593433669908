import React, { Suspense, lazy, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { connect } from 'react-redux'

import { Switch, Route } from 'react-router-dom'
import bindDispatch from '../common/util/redux'
import Footer from '../components/common/Footer'
import ErrorAlert from '../components/common/ErrorAlert'
import { ReactComponent as LoaderIcon } from '../icons/loading.svg'
import '../style/defaultLayout.less'
import GroupDetailsAdmin from '../pages/Groups/GroupDetailsAdmin'
import SuccessAlert from '../components/common/SuccessAlert'

const Movies = lazy(() => import('../pages/Movies'))
const AppliancePage = lazy(() => import('../pages/AppliancePage/AppliancePage'))
const ApplianceDetailsPage = lazy(() => import('../pages/AppliancePage/ApplianceDetailsPage'))
const ErrorPage = lazy(() => import('../pages/errorPage/errorPage'))
const UsersPage = lazy(() => import('../pages/UsersPage/UsersPage'))
const ReportsPage = lazy(() => import('../pages/ReportsPage/ReportsPage'))

const AdminUsersPage = lazy(() => import('../pages/AdminUsersPage/AdminUsersPage'))
const CreateGroup = lazy(() => import('../pages/CreateGroup/CreateGroup'))
const Groups = lazy(() => import('../pages/Groups/Groups'))
const GroupDetail = lazy(() => import('../pages/Groups/GroupDetail'))
const EditGroup = lazy(() => import('../pages/EditGroup/EditGroup'))
const FacilityDevices = lazy(() => import('../pages/Facilities/FacilityDevices'))

const DefaultLayout = ({ user, app: { errors, successMessages }, actions: { fetchUserInfo } }) => {
  useEffect(() => {
    fetchUserInfo()
  }, [fetchUserInfo])

  return (
    <main className="layout-wrapper qw-relative ">
      {user.info ? (
        <Layout id="mainLayout" className="qw-p-none">
          <Suspense fallback={<LoaderIcon className="page-loader" />}>
            <Switch>
              <Route exact path="/" component={Movies} />
              <Route exact path="/appliances" component={AppliancePage} />
              <Route exact path="/appliances/:applianceId" component={ApplianceDetailsPage} />
              <Route exact path="/users" component={UsersPage} />
              <Route exact path="/admin-users" component={AdminUsersPage} />
              <Route exact path="/groups" component={Groups} />
              <Route exact path="/groups/:groupId" component={GroupDetail} />
              <Route exact path="/edit-group/:groupId" component={EditGroup} />
              <Route exact path="/groups-admin/:groupId" component={GroupDetailsAdmin} />
              <Route exact path="/create-group" component={CreateGroup} />
              <Route exact path="/facilities/:facilityId" component={FacilityDevices} />
              <Route exact path="/reports" component={ReportsPage} />
              <Route path="*" component={ErrorPage} />
            </Switch>
          </Suspense>
        </Layout>
      ) : (
        <LoaderIcon className="page-loader qw-absolute qw-left-0 qw-right-0 qw-top-0 qw-bottom-0" />
      )}
      <Footer />
      <ErrorAlert errors={errors} />
      <SuccessAlert successMessages={successMessages} />
    </main>
  )
}

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.user
  }
}

DefaultLayout.propTypes = {
  app: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
}

export default connect(mapStateToProps, bindDispatch)(DefaultLayout)
