import update from 'immutability-helper'
import { I18n } from 'react-redux-i18n'
import qwInbox from '../common/client/qwInbox'
import { displayError } from './app'
import { FilterTypes } from '../common/enums'
import cinemasSearch from '../common/client/cinemasSearch'
import axios from 'axios'
import { debounce } from 'lodash'
import { frameTheatreAddress } from '../common/util/helper'

const TOGGLE_FETCH_IN_PROGESSS = 'inboxFilters/TOGGLE_FETCH_IN_PROGESSS'
const RECEIVED_EXPIRING_DAYS = 'inboxFilters/RECEIVED_EXPIRING_DAYS'
const UPDATE_SELECTED_KDM_VALIDITY_DATA = 'inboxFilters/UPDATE_SELECTED_KDM_VALIDITY_DATA'
const UPDATE_SELECTED_COMPOSITION_TYPE_DATA = 'inboxFilters/UPDATE_SELECTED_COMPOSITION_TYPE_DATA'
const UPDATE_SELECTED_DCP_DOWNLOAD_STATUS_DATA =
  'inboxFilters/UPDATE_SELECTED_DCP_DOWNLOAD_STATUS_DATA'
const UPDATE_SELECTED_DCP_INGEST_STATUS_DATA = 'inboxFilters/UPDATE_SELECTED_DCP_INGEST_STATUS_DATA'
const UPDATE_THEATRE_SELECTED_OPTIONS = 'inboxFilters/UPDATE_THEATRE_SELECTED_OPTIONS'
const UPDATE_SELECTED_KDM_DELIVERY_STATUS_DATA =
  'inboxFilters/UPDATE_SELECTED_KDM_DELIVERY_STATUS_DATA'
const UPDATE_SELECTED_KDM_INGEST_STATUS_DATA = 'inboxFilters/UPDATE_SELECTED_KDM_INGEST_STATUS_DATA'
const TOGGLE_THEATRE_SEARCH_IN_PROGRESS = 'inboxFilters/TOGGLE_THEATRE_SEARCH_IN_PROGRESS'
const RECEIVED_SEARCHED_THEATRES = 'inboxFilters/RECEIVED_SEARCHED_THEATRES'
const UPDATE_FILTERS = 'inboxFilters/UPDATE_FILTERS'

const initialState = {
  selectedList: [],
  filterList: [],
  fetchInProgress: false,
  theatreSearchLimit: 5,
  theatreSearchInProgress: false,
  searchedTheatres: [],
  kdmValiditySelectedOptions: [],
  compositionTypeSelectedOptions: [],
  dcpDownloadStatusSelectedOptions: [],
  dcpIngestStatusSelectedOptions: [],
  kdmDeliveryStatusSelectedOptions: [],
  kdmIngestStatusSelectedOptions: [],
  theatreSelectedOptions: []
}

const { CancelToken } = axios
let source = CancelToken.source()

export const getTheatresSearchDebounced = debounce(getTheatresSearch, 500)

function formatFilterlist(payload) {
  const filterList = []

  if (payload === 0) {
    return filterList.filter(filter => filter.key !== 'expiring')
  }

  return filterList
}

function formatRecievedTheatresList(list) {
  return list.map(theatre => ({
    id: theatre.uuid,
    name: theatre.name,
    displayName: theatre.name,
    subLabel: frameTheatreAddress(theatre),
    value: theatre.name,
    type: FilterTypes.THEATRES
  }))
}

function receivedExpiringDays(payload) {
  return { type: RECEIVED_EXPIRING_DAYS, payload: formatFilterlist(payload) }
}

function toggleFetchInProgess(payload) {
  return {
    type: TOGGLE_FETCH_IN_PROGESSS,
    payload
  }
}

export function updateSelectedKdmValidityOptions(payload) {
  return { type: UPDATE_SELECTED_KDM_VALIDITY_DATA, payload }
}

export function updateSelectedCompositionTypeOptions(payload) {
  return { type: UPDATE_SELECTED_COMPOSITION_TYPE_DATA, payload }
}

export function updateSelectedDcpDownloadStatusOptions(payload) {
  return { type: UPDATE_SELECTED_DCP_DOWNLOAD_STATUS_DATA, payload }
}

export function updateSelectedDcpIngestStatusOptions(payload) {
  return { type: UPDATE_SELECTED_DCP_INGEST_STATUS_DATA, payload }
}

export function updateSelectedKdmDeliveryStatusOptions(payload) {
  return { type: UPDATE_SELECTED_KDM_DELIVERY_STATUS_DATA, payload }
}

export function updateSelectedKdmIngestStatusOptions(payload) {
  return { type: UPDATE_SELECTED_KDM_INGEST_STATUS_DATA, payload }
}

export function updateSelectedTheatreOpitons(payload) {
  return { type: UPDATE_THEATRE_SELECTED_OPTIONS, payload }
}

export function receivedSearchedTheatres(payload) {
  return { type: RECEIVED_SEARCHED_THEATRES, payload }
}

function toggleTheatreSearchInProgess(payload) {
  return {
    type: TOGGLE_THEATRE_SEARCH_IN_PROGRESS,
    payload
  }
}

export function getTheatresSearch(query) {
  return (dispatch, getState) => {
    const { theatreSearchLimit } = getState().inboxFilters
    dispatch(receivedSearchedTheatres([]))
    dispatch(toggleTheatreSearchInProgess(true))
    return cinemasSearch
      .get(`/facilities/search?q=${query}&ps=${theatreSearchLimit}&offset=0`, {
        cancelToken: source.token
      })
      .then(result => {
        dispatch(toggleTheatreSearchInProgess(false))
        dispatch(receivedSearchedTheatres(formatRecievedTheatresList(result.data.facilities)))
      })
      .catch(error => {
        dispatch(toggleTheatreSearchInProgess(false))
        console.log('the error is : ', error)
        if (!axios.isCancel(error)) {
          dispatch(displayError(I18n.t('errors.fetchTheatres')))
        }
      })
  }
}

export function clearFilterData(filterType, value) {
  return (dispatch, getState) => {
    if (filterType === 'all') {
      dispatch(updateSelectedKdmValidityOptions([]))
      dispatch(updateSelectedCompositionTypeOptions([]))
      dispatch(updateSelectedDcpDownloadStatusOptions([]))
      dispatch(updateSelectedDcpIngestStatusOptions([]))
      dispatch(updateSelectedKdmDeliveryStatusOptions([]))
      dispatch(updateSelectedKdmIngestStatusOptions([]))
      dispatch(updateSelectedTheatreOpitons([]))
    }
    if (filterType === FilterTypes.KDM_VALIDITY) {
      const kdmValiditySelectedOptions = getState().inboxFilters.kdmValiditySelectedOptions.filter(
        option => option.id !== value.key
      )
      dispatch(updateSelectedKdmValidityOptions([...kdmValiditySelectedOptions]))
    }
    if (filterType === FilterTypes.COMPOSTION_TYPE) {
      const compositionTypeSelectedOptions = getState().inboxFilters.compositionTypeSelectedOptions.filter(
        option => option.id !== value.key
      )
      dispatch(updateSelectedCompositionTypeOptions([...compositionTypeSelectedOptions]))
    }
    if (filterType === FilterTypes.DCP_DOWNLOAD_STATUS) {
      const dcpDownloadStatusSelectedOptions = getState().inboxFilters.dcpDownloadStatusSelectedOptions.filter(
        option => option.id !== value.key
      )
      dispatch(updateSelectedDcpDownloadStatusOptions([...dcpDownloadStatusSelectedOptions]))
    }
    if (filterType === FilterTypes.DCP_INGEST_STATUS) {
      const dcpIngestStatusSelectedOptions = getState().inboxFilters.dcpIngestStatusSelectedOptions.filter(
        option => option.id !== value.key
      )
      dispatch(updateSelectedDcpIngestStatusOptions([...dcpIngestStatusSelectedOptions]))
    }
    if (filterType === FilterTypes.KDM_DELIVERY_STATUS) {
      const kdmDeliveryStatusSelectedOptions = getState().inboxFilters.kdmDeliveryStatusSelectedOptions.filter(
        option => option.id !== value.key
      )
      dispatch(updateSelectedKdmDeliveryStatusOptions([...kdmDeliveryStatusSelectedOptions]))
    }
    if (filterType === FilterTypes.KDM_INGEST_STATUS) {
      const kdmIngestStatusSelectedOptions = getState().inboxFilters.kdmIngestStatusSelectedOptions.filter(
        option => option.id !== value.key
      )
      dispatch(updateSelectedKdmIngestStatusOptions([...kdmIngestStatusSelectedOptions]))
    }
    if (filterType === FilterTypes.THEATRES) {
      const theatreSelectedOptions = getState().inboxFilters.theatreSelectedOptions.filter(
        option => option.id !== value.key
      )
      dispatch(updateSelectedTheatreOpitons([...theatreSelectedOptions]))
    }
  }
}

const updateFilters = options => {
  return {
    type: UPDATE_FILTERS,
    payload: options
  }
}

export function handleFilterClick(options) {
  return dispatch => {
    dispatch(updateFilters(options))
  }
}

export function fetchFilterSettings() {
  return dispatch => {
    dispatch(toggleFetchInProgess(true))
    return qwInbox
      .get('/filter/options')
      .then(res => {
        dispatch(toggleFetchInProgess(false))
        dispatch(receivedExpiringDays(res.data.kdmExpiringInDays))
      })
      .catch(() => {
        dispatch(toggleFetchInProgess(false))
        dispatch(displayError(I18n.t('errors.fetchFilterSettings')))
      })
  }
}

export default function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_FETCH_IN_PROGESSS:
      return update(state, { fetchInProgress: { $set: payload } })
    case RECEIVED_EXPIRING_DAYS:
      return update(state, { filterList: { $set: payload } })
    case UPDATE_SELECTED_KDM_VALIDITY_DATA:
      return update(state, { kdmValiditySelectedOptions: { $set: payload } })
    case UPDATE_SELECTED_COMPOSITION_TYPE_DATA:
      return update(state, { compositionTypeSelectedOptions: { $set: payload } })
    case UPDATE_SELECTED_DCP_DOWNLOAD_STATUS_DATA:
      return update(state, { dcpDownloadStatusSelectedOptions: { $set: payload } })
    case UPDATE_SELECTED_DCP_INGEST_STATUS_DATA:
      return update(state, { dcpIngestStatusSelectedOptions: { $set: payload } })
    case UPDATE_SELECTED_KDM_DELIVERY_STATUS_DATA:
      return update(state, { kdmDeliveryStatusSelectedOptions: { $set: payload } })
    case UPDATE_SELECTED_KDM_INGEST_STATUS_DATA:
      return update(state, { kdmIngestStatusSelectedOptions: { $set: payload } })
    case TOGGLE_THEATRE_SEARCH_IN_PROGRESS:
      return update(state, { theatreSearchInProgress: { $set: payload } })
    case UPDATE_THEATRE_SELECTED_OPTIONS:
      return update(state, { theatreSelectedOptions: { $set: payload } })
    case RECEIVED_SEARCHED_THEATRES:
      return update(state, { searchedTheatres: { $set: payload } })
    case UPDATE_FILTERS:
      return update(state, { $merge: payload })
    default:
      return state
  }
}
