import update from 'immutability-helper'
import { I18n } from 'react-redux-i18n'
import { displayError } from './app'
import qwTheatreACL from '../common/client/qwTheatreAccessControlList'
import { getComapnyId } from '../common/util/helper'

const initialState = {
  theatresInSelection: [],
  groups: [],
  offset: 0,
  ps: 10,
  loading: false,
  hasMore: false,
  groupDetail: {
    groupName: '',
    theatres: [],
    users: []
  },
  oldGroupName: '',
  oldTheatresList: []
}

const UPDATE_THEATRE_IN_GROUP = 'groups/UPDATE_THEATRE_IN_GROUP'
const SET_GROUPS = 'groups/SET_GROUPS'
const SET_LOADING = 'groups/SET_LOADING'
const SET_HAS_MORE = 'groups/SET_HAS_MORE'
const SET_OFFSET = 'groups/SET_OFFSET'
const SET_GROUP_DETAIL_THEATRES = 'groups/SET_GROUP_DETAIL_THEATRES'
const SET_GROUP_DETAIL_NAME = 'groups/SET_GROUP_DETAIL_NAME'
const SET_OLD_GROUP_NAME ='groups/SET_OLD_GROUP_NAME'
const SET_OLD_THEATRE_LIST='groups/SET_OLD_THEATRE_LIST'

export function updateTheatreInGroup(value) {
  return { type: UPDATE_THEATRE_IN_GROUP, payload: [...value] }
}

function setGroups(groups) {
  return {
    type: SET_GROUPS,
    payload: groups
  }
}

export function setGroupDetailName(groups) {
  return {
    type: SET_GROUP_DETAIL_NAME,
    payload: groups
  }
}

function setTheatresInGroup(theatres) {
  return {
    type: SET_GROUP_DETAIL_THEATRES,
    payload: theatres
  }
}

function setHasMore(hasMore) {
  return {
    type: SET_HAS_MORE,
    payload: hasMore
  }
}

export function setPageLoading(hasMore) {
  return {
    type: SET_LOADING,
    payload: hasMore
  }
}

function setOffset(offset) {
  return {
    type: SET_OFFSET,
    payload: offset
  }
}
export function setOldGroupName(name) {
  return {
    type: SET_OLD_GROUP_NAME,
    payload: name
  }
}
export function setOldTheatresList(value) {
  return {
    type: SET_OLD_THEATRE_LIST,
    payload: value
  }
}

export function fetchGroups(offset = 0) {
  return dispatch => {
    dispatch(setPageLoading(true))
    qwTheatreACL
      .get(`/v1/groups?ps=${initialState.ps}&offset=${offset}`)
      .then(response => {
        dispatch(setGroups(response.data.groups))
        dispatch(setHasMore(response.data.hasMore))
        dispatch(setOffset(offset))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchGroups')))
        dispatch(setGroups([]))
      })
      .finally(() => {
        dispatch(setPageLoading(false))
      })
  }
}
export function fetchGroupsForAdmin(offset = 0) {
  return dispatch => {
    dispatch(setPageLoading(true))
    qwTheatreACL
      .get(`/v1/admin/company/${getComapnyId()}/groups?ps=${initialState.ps}&offset=${offset}`)
      .then(response => {
        dispatch(setGroups(response.data.groups))
        dispatch(setHasMore(response.data.hasMore))
        dispatch(setOffset(offset))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchGroups')))
        dispatch(setGroups([]))
      })
      .finally(() => {
        dispatch(setPageLoading(false))
      })
  }
}

export function fetchTheatresInGroup(groupId) {
  return dispatch => {
    dispatch(setPageLoading(true))
    qwTheatreACL
      .get(`/v1/groups/${groupId}/theatres`)
      .then(response => {

        dispatch(setTheatresInGroup(response.data.theatres))
        dispatch(setGroupDetailName(response.data.name))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchGroups')))
        dispatch(setTheatresInGroup([]))
      })
      .finally(() => {
        dispatch(setPageLoading(false))
      })
  }
}
export function fetchTheatresInGroupForAdmin(groupId) {
  return dispatch => {
    dispatch(setPageLoading(true))
    qwTheatreACL
      .get(`/v1/admin/groups/${groupId}/theatres`)
      .then(response => {
        dispatch(setTheatresInGroup(response.data.theatres))
        dispatch(setGroupDetailName(response.data.name))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchGroups')))
        dispatch(setTheatresInGroup([]))
      })
      .finally(() => {
        dispatch(setPageLoading(false))
      })
  }
}
export function fetchTheatresInGroupForEdit(groupId) {
  return dispatch => {
    dispatch(setPageLoading(true))
    qwTheatreACL
      .get(`/v1/groups/${groupId}/theatres`)
      .then(response => {
        dispatch(updateTheatreInGroup(response.data.theatres))
        dispatch(setOldTheatresList(response.data.theatres))
        dispatch(setOldGroupName(response.data.name))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchGroups')))
        dispatch(setTheatresInGroup([]))
        dispatch(setOldGroupName(''))
      })
      .finally(() => {
        dispatch(setPageLoading(false))
      })
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_THEATRE_IN_GROUP:
      return update(state, { theatresInSelection: { $set: action.payload } })
    case SET_GROUPS:
      return update(state, { groups: { $set: action.payload } })
    case SET_GROUP_DETAIL_THEATRES:
      return update(state, { groupDetail: { theatres: { $set: action.payload } } })
    case SET_GROUP_DETAIL_NAME:
      return update(state, { groupDetail: { groupName: { $set: action.payload } } })
    case SET_LOADING:
      return update(state, { loading: { $set: action.payload } })
    case SET_HAS_MORE:
      return update(state, { hasMore: { $set: action.payload } })
    case SET_OFFSET:
      return update(state, { offset: { $set: action.payload } })
    case SET_OLD_GROUP_NAME:
      return update(state, { oldGroupName: { $set: action.payload } })
      case SET_OLD_THEATRE_LIST:
        return update(state, { oldTheatresList: { $set: [...action.payload] } })

    default:
      return state
  }
}
