import update from 'immutability-helper'

//* EVENTS */
const DISPLAY_MODAL = 'common/DISPLAY'
const HIDE_MODAL = 'common/HIDE'

//* Actions *//
export function showModal(title, content, width) {
  return {
    type: DISPLAY_MODAL,
    payload: {
      title,
      content,
      width
    }
  }
}

export function hideModal() {
  return {
    type: HIDE_MODAL
  }
}

//* Reducers *//
export default function reducer(
  state = {
    visible: false,
    title: '',
    content: null,
    width: 200
  },
  action
) {
  switch (action.type) {
    case DISPLAY_MODAL: {
      const { title, content, width } = action.payload
      return update(state, {
        visible: { $set: true },
        content: { $set: content },
        title: { $set: title },
        width: { $set: width }
      })
    }
    case HIDE_MODAL: {
      return update(state, {
        visible: { $set: false },
        content: { $set: null }
      })
    }
    default:
      return state
  }
}
