import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'

const ErrorAlert = ({ errors }) => {
  return (
    <div className="qw-fixed qw-left-0 qw-bottom-0 qw-m-small">
      {errors.map(error => (
        <Alert message={error} key={error} className="qw-m-small" type="error" closable showIcon />
      ))}
    </div>
  )
}

ErrorAlert.propTypes = {
  errors: PropTypes.array.isRequired
}

export default ErrorAlert
