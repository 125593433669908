import axios from 'axios'
import _ from 'lodash'
import update from 'immutability-helper'
import { I18n } from 'react-redux-i18n'
import cinemasSearch from '../common/client/cinemasSearch'
import { displayError } from './app'

const { CancelToken } = axios
let source = CancelToken.source()

//* EVENTS */
const FETCH_THEATRES = 'theatres/FETCH_THEATRES'
const TOGGLE_THEATRE_SIDEBAR = 'theatres/TOGGLE_THEATRE_SIDEBAR'
const TOGGLE_FETCH_IN_PROGESSS = 'theatres/TOGGLE_FETCH_IN_PROGESSS'
const FETCH_THEATRES_ERROR = 'theatres/FETCH_THEATRES_ERROR'
const UPDATE_THEATRE_SEARCH_QUERY = 'theatres/UPDATE_THEATRE_SEARCH_QUERY'
const REMOVE_THEATRES = 'theatres/REMOVE_THEATRES'
const CLEAR_THEATRE_SEARCH_QUERY = 'theatres/CLEAR_THEATRE_SEARCH_QUERY'
const ps = 20

//* Actions *//

export function removeTheatres() {
  return {
    type: REMOVE_THEATRES
  }
}

export function toggleSideBar() {
  return {
    type: TOGGLE_THEATRE_SIDEBAR
  }
}

function setTheatres(theatres, nextOffset, operation) {
  return {
    type: FETCH_THEATRES,
    payload: {
      theatres,
      nextOffset
    },
    operation
  }
}

function toggleFetchInProgess(bool) {
  return {
    type: TOGGLE_FETCH_IN_PROGESSS,
    bool
  }
}

export function fetchTheatresError() {
  return {
    type: FETCH_THEATRES_ERROR
  }
}

export function updateTheatreSearchQuery(query) {
  return {
    type: UPDATE_THEATRE_SEARCH_QUERY,
    query
  }
}

export function fetchTheatres(isFresh) {
  return (dispatch, getState) => {
    if (isFresh && source) {
      source.cancel()
      source = CancelToken.source()
    }
    if (!getState().theatres.fetchInProgress || isFresh) {
      dispatch(toggleFetchInProgess(true))
      if (isFresh) {
        dispatch(removeTheatres())
      }
      const offset = isFresh ? 0 : getState().theatres.offset
      let url = `/facilities/search?ps=${ps}&offset=${offset}`
      if (getState().theatres.q.length) {
        url = `${url}&q=${getState().theatres.q}`
      }

      const operation = isFresh ? '$set' : '$push'
      cinemasSearch
        .get(url, { cancelToken: source.token })
        .then(response => {
          dispatch(setTheatres(response.data, offset + ps, operation))
          dispatch(toggleFetchInProgess(false))
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            dispatch(displayError(I18n.t('errors.fetchTheatres')))
            dispatch(fetchTheatresError())
          }
        })
    }
  }
}

export function clearTheatreSearchQuery() {
  return dispatch => {
    dispatch({
      type: CLEAR_THEATRE_SEARCH_QUERY
    })
    dispatch(fetchTheatres(true))
  }
}

//* Reducers *//
export default function reducer(
  state = {
    theatres: [],
    showSideBar: true,
    offset: 0,
    hasMore: true,
    fetchInProgress: false,
    q: ''
  },
  action
) {
  switch (action.type) {
    case FETCH_THEATRES: {
      const data = {}
      if (!_.isEmpty(action.payload.theatres)) {
        const tempArray =
          action.payload.theatres &&
          action.payload.theatres.facilities.map(item => {
            const tempItem = item
            tempItem.id = item.uuid
            return tempItem
          })
        data.theatres = { [action.operation]: tempArray }
        data.offset = { $set: action.payload.nextOffset }
      } else {
        data.hasMore = { $set: false }
      }
      return update(state, data)
    }

    case TOGGLE_THEATRE_SIDEBAR: {
      return update(state, { showSideBar: { $set: !state.showSideBar } })
    }

    case TOGGLE_FETCH_IN_PROGESSS: {
      return update(state, { fetchInProgress: { $set: action.bool } })
    }

    case FETCH_THEATRES_ERROR: {
      return update(state, {
        hasMore: { $set: false },
        fetchInProgress: { $set: false }
      })
    }

    case UPDATE_THEATRE_SEARCH_QUERY:
      return update(state, {
        q: { $set: action.query }
      })

    case REMOVE_THEATRES: {
      return update(state, {
        theatres: { $set: [] }
      })
    }

    case CLEAR_THEATRE_SEARCH_QUERY: {
      return update(state, {
        q: { $set: '' }
      })
    }
    default:
      return state
  }
}
