export const CINEMA_UI_URL = process.env.REACT_APP_CINEMA_UI_URL
export const QW_INBOX_API_URL = process.env.REACT_APP_QW_INBOX_API_URL
export const ZIPPO_URL = process.env.REACT_APP_ZIPPO_URL
export const DEVICE_MANAGER_ROLE = process.env.REACT_APP_DEVICE_MANAGER_ROLE
export const REFETCH_COMPOSITION_TIME = process.env.REACT_APP_REFETCH_COMPOSITION_TIME_SEC
export const UPDATE_REFETCH_TIMEOUT = process.env.REACT_APP_UPDATE_REFETCH_TIMEOUT_SEC
export const MONARCH_URL = process.env.REACT_APP_MONARCH_API_URL
export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL
export const APPLIANCE_BASE_URL = process.env.REACT_APP_APPLIANCE_BASE_URL
export const QUBE_ACCOUNT_BASE_URL = process.env.REACT_APP_QUBE_ACCOUNT_BASE_URL
export const THEATRE_ACL_BASE_URL = process.env.REACT_APP_THEATRE_ACL_BASE_URL
export const QW_SERVICE_REPORTS_API_URL = process.env.REACT_APP_QW_SERVICE_REPORTS_API_URL
export const QW_APPLIANCE_HISTORY_URL = process.env.REACT_APP_QW_APPLIANCE_HISTORY_URL
export const QW_DIMENSIONS_URL = process.env.REACT_APP_QW_DIMENSIONS_URL
