import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'

const SuccessAlert = ({ successMessages }) => {
  return (
    <div className="qw-fixed qw-right-0 qw-top-0 qw-m-small">
      {successMessages.map(message => (
        <Alert
          message={message}
          key={message}
          className="qw-m-small"
          type="success"
          closable
          showIcon
        />
      ))}
    </div>
  )
}

SuccessAlert.propTypes = {
  successMessages: PropTypes.array.isRequired
}

export default SuccessAlert
